@import "@fontsource/inter/100.css";
@import "@fontsource/inter/200.css";
@import "@fontsource/inter/300.css";
@import "@fontsource/inter/400.css";
@import "@fontsource/inter/500.css";
@import "@fontsource/inter/600.css";
@import "@fontsource/inter/700.css";
@import "@fontsource/inter/800.css";
@import "@fontsource/inter/900.css";

*,
*:before,
*:after {
  box-sizing: border-box;
}

.beamer_defaultBeamerSelector {
  display: none;
}

.custom-table thead tr:nth-child(odd) th {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  background-color: hsl(199, 100%, 95%);
}

.custom-table thead tr:nth-child(even) th {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
}

.ht_master tr:nth-of-type(even) > td {
  background-color: hsl(199, 100%, 95%);
}

/* Changes to elements */
* {
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: var(--font-color-brand);
  line-height: 129%;
  letter-spacing: 0.02em;  
}

html {
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

div {
  padding: 0px;
  margin: 0px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(240,241,244) !important;
}

input {
  border-radius: 0;
}

.react-datepicker-wrapper {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__input-container {
  width: 30%;
}

.react-datepicker__input-container input {
  border: none;
  text-align: center;
  border-radius: 8px;
  height: 25px;
  z-index: 9000;
}

#left {
  margin-right: 5px;
}

#right {
  margin-left: 5px;
}